.character-background {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  padding-bottom: 20px;
}

.character-background_header {
  position: relative;
  top: 0;
  width: 100%;
  background-image: url(../assets/images/header_top_center.png);
  background-size: contain;
  height: 100%;
  background-position: top center;
  background-repeat: no-repeat;
}

.character-background_buttons {
  position: absolute;
  width: 100%;
  padding: 5px;
  top: 5px;
}

.character-background_buttons > .page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.character-background_buttons button {
  width: 85px;
}
