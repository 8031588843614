body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
body:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-image: url("./assets/images/wallpaper.jpg");
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%);
  opacity: 0.15;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
}

.page-content {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  width: 100%;
  max-width: calc(100% - 200px);
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  padding-bottom: 10px;
}

@media screen and (max-width: 1024px) {
  .page-content {
    max-width: calc(100% - 50px);
  }
}
@media screen and (max-width: 728px) {
  .page-content {
    max-width: calc(100% - 20px);
  }
}
@media screen and (max-width: 600px) {
  .page-content {
    max-width: 100%;
  }
}
