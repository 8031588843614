.registration-page-form {
  display: flex;
  align-items: flex-end;
}

.registration-form {
  position: relative;
  width: 50%;
  color: #7e7e7e;
  overflow: hidden;
  background-color: black;
  min-width: 320px;
  margin: auto;
}
