.login-page-form,
.registration-page-form,
.forgotpassword-page-form {
  display: flex;
  align-items: flex-end;
}

.login-form,
.registration-form,
.forgotpassword-form {
  position: relative;
  width: 50%;
  color: #7e7e7e;
  overflow: hidden;
  background-color: black;
  min-width: 320px;
  margin: auto;
}

.login-form input {
  width: 100%;
}

.login-form form {
  width: 100%;
}

.login-form_buttons {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.login-form_buttons a,
.login-form_buttons button {
  width: 100%;
  height: 100%;
  text-align: center;
}

.login-form form div {
  margin-bottom: 10px;
}
