.character-sheetpage {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
}
 
.character-sheetpage_characteristics {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.character-sheetpage-debuginfo {
  display: none;
  position: relative;
    width: 100%;
    height: 50vh;
    overflow: auto;
}
.character-sheetpage-debuginfo pre {
  padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 100%;
    overflow-y: auto;
    white-space: pre-wrap;
    max-height: 100%;
}

.character-sheetpage_characteristics_stat {
  position: relative;
  width: calc(25% - 4px);
  text-align: center;
  border: 1px solid #cf9b47;
  margin: 2px;
  cursor: pointer;
}

.character-sheetpage_points {
  position: relative;
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  border: 1px solid #cf9b47;
  margin: 10px 2px;
}

.character-sheetpage_points > .character-sheetpage_points_stat {
  width: 33%;
  text-align: center;
}

.character-sheetpage_points_stat_name {
  font-size: 12px;
  font-weight: bold;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.character-sheetpage_skills {
  position: relative;
  column-count: 3;
  width: 100%;
}

.character-sheetpage_skills_stat {
  position: relative;
  height: 50px;
  display: flex;
  border: 1px solid #cf9b47;
  break-inside: avoid;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
}

.character-hover {
  cursor: pointer;
}
.character-hover:hover {
  color: white;
  background-attachment: fixed;
  background-image: url("../../assets/images/wallpaper.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

.sheetpage_skills_stat_name {
  position: relative;
  font-size: 12px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
}

.sheetpage_skills_stat_value {
  position: relative;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
  width: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.character-sheetpage h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.character-sheetpage h2 svg {
  margin-left: 10px;
}

.icon-edit-character {
  cursor: pointer;
}
.icon-edit-character:hover {
  color: white
}

.character-sheetpage_wrapper {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.character-sheetpage_sections {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.character-sheetpage_sectioncontent {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow: auto;
}

.character-sheetpage_sections ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.character-sheetpage_sections li {
  width: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  line-height: 50px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.character-sheetpage_sections li.active {
  font-weight: bold;
  color: white;
  border-color: #cf9b47;
  background-attachment: fixed;
  background-image: url(../../assets/images/wallpaper.jpg);
  background-position: center;
  background-repeat: no-repeat;
}

.character-sheetpage_characteristics_stat_value input, .sheetpage_skills_stat_value input {
  width: 100%;
  max-width: 45px;
  text-align: center;
  border: none;
}

.character-sheetpage.edit-template {
  overflow: auto;
}

.character-sheetpage_weapons {
  position: relative;
  display: table;
  width: 100%;
  margin-top: 50px;
  padding: 20px;
  color: black;
}
.character-sheetpage_weapons:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-image: url('../../assets/images/weapons_background.jpg');
  background-size: 100%;
}
.character-sheetpage_weapons_row {
  position: relative;
  display: table-row;
  width: 100%;
}
.character-sheetpage_weapons_row:first-child {
  font-weight: bold;
}
.character-sheetpage_weapons_row > div {
  position: relative;
  display: table-cell;
  line-height: 24px;
  font-size: 14px;
}
.character-sheetpage_weapons_row > div:first-child {
  font-weight: bold;
}
.character-sheetpage_weapons_row + .character-sheetpage_weapons_row > div {
  border-top: 1px solid #b99b5f;
}
.character-sheetpage_weapons_row > div + div {
  border-left: 1px solid #b99b5f;
}
.character-sheetpage_weapons_row:first-child > div {
  text-align: center;
}
.character-sheetpage_weapons_row:not(:first-child) > div:not(.row_weapon_name) {
  text-align: center;
}

.character-sheetpage_header {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.character-sheetpage_header img {
  width: 100%;
  max-width: 200px;
} 

.character-sheetpage_header_left {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 20px;
  display: table;
  max-width: 450px;
}
.character-sheetpage_header_left > div {
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.character-sheetpage_header_left > div > * {
  display: table-cell;
}
.character-sheetpage_header_left > div > b {
  padding-right: 10px;
}
.character-sheetpage_header_left > div > span {
  flex-grow: 1;
  flex-shrink: 1;
}
.character-sheetpage_points_stat_value span + span:before {
  content: '/';
  margin-left: 5px;
  margin-right: 5px;
}
.character-sheetpage_points_stat_value input {
  width: 50px;
  text-align: center;
}

.sheetpage_skills_stat_name input {
  width: 100%;
  line-height: 15px;
  height: 20px;
  border: none;
}

.sheetpage_skills_stat_value input {
  width: 100%;
  line-height: 15px;
  height: 20px;
  border: none;
}

.character-sheetpage_weapons_row.weapon-hover {
  cursor: pointer;
}
.character-sheetpage_weapons_row.weapon-hover:hover {
  background-color: #f5f5f5;
}

.character-sheetpage_weapons_row input {
  width: 100%;
}

.row_background_block_title {
  font-weight: bold;
  text-transform: capitalize;
}

.row_background_block {
  margin-bottom: 50px;
}

.row_background_block_content textarea {
  width: 100%;
  resize: vertical;
  min-height: 150px;
}

.character-sheetpage_skills {
  padding: 0px 10px;
  column-count: 1;
}

.sheetpage_skills_stat_delete button {
  width: 20px;
  text-align: center;
  height: 20px;
  padding: 0;
  margin: 9px 1px;
  line-height: 14px;
  border: unset;
}

.sheetpage_skills_stat_delete {
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 400px) {
  .character-sheetpage_skills {
    column-count: 2;
  }  
}
@media screen and (min-width: 700px) {
  .character-sheetpage_skills {
    column-count: 3;
  }  
}
@media screen and (min-width: 1500px) {
  .character-sheetpage_skills {
    column-count: 4;
  }  
}
@media screen and (min-width: 1900px ) {
  .character-sheetpage_skills {
    column-count: 5;
  }  
}

